var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "youxuan-log outer" },
    [
      _c(
        "div",
        { staticClass: "app-item", staticStyle: { "margin-bottom": "50px" } },
        [
          _c(
            "nb-form",
            _vm._g(
              _vm._b(
                {
                  model: {
                    value: _vm.forms,
                    callback: function ($$v) {
                      _vm.forms = $$v
                    },
                    expression: "forms",
                  },
                },
                "nb-form",
                _vm.formInfo,
                false
              ),
              _vm.formEvents
            )
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-item" },
        [
          _c(
            "nb-table",
            _vm._b(
              {
                scopedSlots: _vm._u([
                  {
                    key: "details",
                    fn: function ({ row }) {
                      return [
                        row.optType === 1 || row.optType === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.lookDetails(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              "nb-table",
              _vm.tableInfo,
              false
            )
          ),
        ],
        1
      ),
      _vm.showoff
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showoff,
                title: _vm.dialogTitle,
                "custom-class": "modify-batch-order",
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showoff = $event
                },
              },
            },
            _vm._l(_vm.dialogArr, function (item, index) {
              return _c("LogDetails", {
                key: index,
                attrs: {
                  title: item.title,
                  desc: item.desc,
                  tableData: item.tableData,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }