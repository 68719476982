<template>
  <div class="youxuan-log outer">
    <div class="app-item" style="margin-bottom: 50px;">
      <nb-form v-model="forms" v-bind="formInfo" v-on="formEvents"></nb-form>
    </div>
    <div class="app-item">
      <nb-table v-bind="tableInfo">
        <template #details="{row}">
          <el-button
            v-if="row.optType === 1 || row.optType === 2"
            type="text"
            size="mini"
            @click="lookDetails(row)"
          >查看</el-button>
        </template>
      </nb-table>
    </div>

    <el-dialog
      v-if="showoff"
      :visible.sync="showoff"
      :title="dialogTitle"
      custom-class="modify-batch-order"
      width="1000px"
    >
      <LogDetails
        v-for="(item, index) in dialogArr" :key="index"
        :title="item.title"
        :desc="item.desc"
        :tableData="item.tableData"
      />
    </el-dialog>
  </div>
</template>

<script>
import {youxuanLogPage} from '@/utils/pageConfigs.js';
import LogDetails from './LogDetails.vue';


export default {
  components: {LogDetails},
  data() {
    this.optTypes = {
      1: '创建并关联商品',
      2: '修改关联商品',
      3: '解除关联',
      4: '解除合作'
    };
    youxuanLogPage.call(this);
    return {
      forms: {},
      showoff: false,
      dialogTitle: '',
      dialogArr: []
    };
  },
  methods: {
    lookDetails(row) {
      this.dialogTitle = this.optTypes[row.optType];
      if (row.optType === 1) {
        this.dialogArr = [
          {title: '供应商商品规格', tableData: row.optContent?.relationSupplierSkus || []},
          {title: '关联商品规格', tableData: row.optContent?.yxSkus || []},
        ];
      } else if (row.optType === 2) {
        this.dialogArr = [
          {title: '', tableData: row.optContent?.yxSkus || []},
        ];
      }
      this.showoff = true;
    },
    getData(args) {
      return new Promise(async resolve => {
        const [startTime, endTime] = this.forms.times || [];
        const params = {
          ...this.forms,
          ...args,
          startTime,
          endTime
        };
        delete params.times;
        const res = await this.$axios.post(this.$api.supplychain.optLogList, params);
        if (res.code === 0) {
          const data = res.data || {};
          const list = data.records || [];

          list.forEach(row => {
            row.optTypeStr = this.optTypes[row.optType];

            if (row.optContent?.relationSupplierSkus) {
              row.optContent.relationSupplierSkus.forEach(item => {
                item.imageList = item.imageIds ? item.imageIds.split(',') : [];
              });
            }
            if (row.optContent?.yxSkus) {
              row.optContent.yxSkus.forEach(item => {
                item.imageList = item.imageIds ? item.imageIds.split(',') : [];
              });
            }
            
          });
          resolve({list, total: +data.total});
        } else {
          resolve({list: [], total: 0});
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.youxuan-log{
  background: #fff;
}
</style>