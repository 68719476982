<template>
  <div class="log-details-pop">
    <div v-if="title" class="title">{{title}}</div>
    <div v-if="desc" class="desc">{{desc}}</div>
    <nb-table v-bind="tableInfo">
      <template #imgs="{row}">
        <el-image
          v-if="row.imageList?.length"
          style="width: 100%;"
          :src="row.imageList[0]"
          :preview-src-list="row.imageList"></el-image>{{row.imageList}}
      </template>
    </nb-table>
  </div>
</template>

<script>
import {logDetailsTablle} from '@/utils/pageConfigs.js';

export default {
  props: {
    title: {type: String, default: ''},
    desc: {type: String, default: ''},
    tableData: {type: Array, default: () => []}
  },
  data() {
    logDetailsTablle.call(this);
    return {};
  },
  methods: {
    getData() {
      return new Promise(resolve => {
        resolve({list: this.tableData, total: 0});
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.log-details-pop{
  margin-bottom: 30px;

  .title{
    margin-bottom: 10px;
  }
  .desc{
    margin-bottom: 10px; color: #F56C6C;
  }
}
</style>