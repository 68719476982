var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "log-details-pop" },
    [
      _vm.title
        ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm.desc
        ? _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))])
        : _vm._e(),
      _c(
        "nb-table",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "imgs",
                fn: function ({ row }) {
                  return [
                    row.imageList?.length
                      ? _c("el-image", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: row.imageList[0],
                            "preview-src-list": row.imageList,
                          },
                        })
                      : _vm._e(),
                    _vm._v(_vm._s(row.imageList) + " "),
                  ]
                },
              },
            ]),
          },
          "nb-table",
          _vm.tableInfo,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }